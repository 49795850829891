import axios from "axios";
import { createRef } from "react";
import querystring from "querystring";

export const tokenRef = createRef();
// const querystring = require("querystring");

interface Parameters {
  url: string;
  data?: any;
  responseType?: any;
  onUploadProgress?: any;
  headers?: any;
}


const sendRequest = {
  post: (params: Parameters) => handleRequest(params, "post"),
  put: (params: Parameters) => handleRequest(params, "put"),
  get: (params: Parameters) => handleRequest(params, "get"),
  delete: (params: Parameters) => handleRequest(params, "delete"),
};

function getSubdomain() {
  const hostname = window.location.hostname;

  const parts = hostname.split(".");
  if (
    (hostname.includes("localhost") && parts.length > 1) ||
    parts.length > 2
  ) {
    return parts[0]; // Return the first element (subdomain)
  } else {
    return null; // No subdomain present
  }
}

export default sendRequest;

function handleRequest(params: Parameters, method: any): Promise<any> {
  console.log("---> API: ", params.url, method);
  return new Promise((resolve, reject) => {
    if (!params.url) {
      reject({ message: "URL is required for http request" });
      return;
    }

    if (params.data && ["get", "delete"].includes(method)) {
      params.url += "?" + querystring.stringify(params.data);
      console.log("@stss", querystring.stringify(params.data));
    }

    request
      .request({
        method,
        responseType: params.responseType,
        url: params.url,
        data: params.data,
        // config: params.config,
        onUploadProgress: params.onUploadProgress,
        headers: {
          ...params.headers,
          // "Content-Type": "application/json",
          // Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
          "x-subdomain": getSubdomain(),
        },
      })
      .then((res: any) => {
        resolve(res?.data?.data || res?.data);
      })
      .catch((err: any) => reject(err));
  });
}

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

request.interceptors.response.use(
  async function (config) {
    return config;
  },
  async function (error) {
    const err = error.toJSON();
    if (err?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.pathname = "/login";
    }
    return Promise.reject(error);
  }
);
